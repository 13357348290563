import Prestalib from "../../prestalib/js/prestalib.js";

export default class SuperLayout extends Prestalib{
  // config;
  // time;
  // urlAjax;
  // progressbar;
  // wrapperId='wrapper';
  // wrapper = null;
  // busy=false;
  // verbose=1;
  // wrapper= null;

  constructor(config){
    super();
    this.config={};
    this.busy=true;
    this.urlAjax='ajax=true&action=page';
    this.progressbar=document.getElementById("progress");
    this.wrapperId='wrapper';
    this.cache=true;
    this.dbg=window.prestashop.debug;

    for(let i of Object.keys(config))
        this.config[i]=config[i];
    
    if(this.config.parallax && this.config.parallax.enabled==true){
      if(this.dbg) this.log('parallax enabled',4);
      window.addEventListener('scroll', this._throttle(()=>this.parallax(), 5));
    }
      

    if(this.config.async && this.config.async.pageload==true){
      if(this.dbg) this.log("initializing ajax page loading....");
      this.initAsync();
    }
    this.busy=false;
    // this.on('page.loaded',()=>alert('laoded'));
    this.wrapper = document.getElementById(this.wrapperId);
    if(!this.wrapper) return;

    // this.wrapper.addEventListener('load', (event) => {
    //   console.log('wrapper loaded');
    // });
    this.observe(this.wrapper,()=>{
      // this.log('wrapper changed');
      // this.event('page.loaded');
    })

    this.initCheckout();
  }
  
  evalScripts(wrapper){
    var arr = wrapper.getElementsByTagName('script');
  }

  _throttle(fn, wait) {
    var time = Date.now();

    return function() {
      if ((time + wait - Date.now()) < 0) {
        // console.log()
        fn();
        time = Date.now();
      }
    }
  }
  
  parallax() {
    var scrolled = window.pageYOffset;
    //var parallax = document.querySelector(".parallax");
    // You can adjust the 0.4 to change the speed
    var coords = '-'+(scrolled * 0.08) + 'px'
    //parallax.style.transform = 'translateY(' + coords + ')';
    document.querySelector('#'+this.wrapperId).style.setProperty('--background-offset',coords);
  }

  initLinks(wrapper,cb){

    if(typeof wrapper == 'undefined')
      wrapper = document;
    if(this.dbg) this.log("init links " + wrapper.nodeName,4);
  	wrapper.querySelectorAll('a').forEach((a)=>{
      let href = a.getAttribute('href');
      if(this._skipLink(a)) return false;
  

      a.addEventListener('click',(event)=>{
        // let href = a.href;

        if(href.indexOf('javascript:')==0){
          if(this.dbg) this.log('js script');
          return false;
        }

        if(a.hostname!=document.location.hostname){
          a.setAttribute('target','_blank');
          if(this.dbg) this.log('different hostname ' + href);
          return true;
        }

        event.preventDefault();
        
        if(this.dbg) this.log('clicked url : '+href,4);
        if(typeof cb == 'function')
          cb();
        if(this.busy) 
          return false
        return this.fetchUrl(href);

      });

    });

    this.event('initLinks',wrapper);
      
  }

  fetchUrl(href,params={}){
    let glue='?';

    let paramsDefault={
      redirect: 'follow',
      history: false,
      mode: 'no-cors'
    }


    if(typeof href =='undefined'){
      this.warning('cannot fetch url : '+href);
      return;
    }
  
    if(href.indexOf('#')>=0){
      href=href.split('#')[0];
    }

    if(!href.indexOf('lucid.imac.itank.pl')<0){
      window.open(href,'_blank');
      return;
    }

    if(href.indexOf('?')>=0) glue='&';

    for(let k in paramsDefault){
      if(typeof params[k]=='undefined')
        params[k]=paramsDefault[k];
    }

    this.busy=true;
    this.progress(30);
    if(this.dbg) this.log('fetch url : '+ href + glue + this.urlAjax,2);
    window.fetch(href+glue+this.urlAjax,params).then(async response=>{
      this.progress(60);
      if(response.redirected){

      }else if(response.ok){

        //response ok
      }else{
        //else
      }
      this.progress(70);
      return response;
    })
    // .then(res => this._fetchConsume(res.body.getReader()))
    .then(response => response.clone().json().catch(() => {
      this.warning('not json ');
      console.log(response);
      this.busy=false;
      return response.text();
    })).then(ret => {
        this.progress(89);
        // console.log(ret);
        this.busy=false;
        this.loadPage(ret);
        if (history) this.pushHistory(href);
    }).then( ()=> {
        this.busy=false;
        this.progress(100);
        setTimeout(() =>{ this.progress(0); }, 300);
    })
    .catch(e=>{
        this.busy=false;
        this.progress(0);
        // console.log(ret);
        this.error(e.message);
    });
  }

  _skipLink(a){
    let href = a.getAttribute('href');
    if(!href  || href.charAt(0)=='#' || href=='' /*|| href.indexOf('javascript:')==0 */ || ( a.rel && (a.rel=='external' || a.rel=='noreferrer'  || a.rel=='nofollow-disabled')) || (a.getAttribute('data-toggle') && a.getAttribute('data-toggle')!='tooltip') || a.classList.contains('fancybox') || a.target=='_blank') return true;
    return false; 
  }

   loadPage(ret){
    if(!ret) {
      this.error('cannot load page '+ret);
      console.log(ret);
      return false;
    }
  
    if(typeof ret == 'string'){
      ret={
        page: ret
      }
    }
  
    if(typeof ret.page == 'undefined'){
      this.log('page undefined',4);
      return false;
    }
  
    //if page empty reload page 
    if(ret.page==''){
      this.warn('empty page, reload : '+window.location.href,4);
      // fetchUrl(window.location.href);
      return false;
    }

    let wrapper = document.getElementById(this.wrapperId);
    wrapper.innerHTML=ret.page;
    wrapper.style.display='block';
    if(ret.header){
      let header = document.getElementById('header');
      if(header) header.innerHTML=ret.header;
      this.initLinks(header);
      this.initForms(header);
      header.classList.remove('is-open');
    }
    //eval scripts
    // this.initLinks(wrapper);
    // this.initForms(wrapper);
    this.initAsync(wrapper);
    // let blockcart = document.getElementById('blockcart-modal');
    // if(blockcart) this.initLinks(blockcart);

    document.body.id=ret.php_self;
    document.body.classList='';

    if(typeof ret.body_classes =='object'){
      for(let i in ret.body_classes){
        try{
          if(!i.includes(' '))
            document.body.classList.add(i);
        }catch(e){
          // cons.log(ret.body_classes);
          this.error(i);
          this.error(e);
        }
      }
    }
    this.event('page.loaded');
  }

  progress(percent=10){
    
    var progressInterval=false;

    function updateProgress(bar){
      
      let width = parseInt(bar.style.width);
      
      if(width > 0 && width<100){
        width+=1;
        bar.style.width=width+'%';
      }else{
        clearInterval(progressInterval);
      }
    }
  
    if(typeof this.progressbar == 'undefined' || this.progressbar==null) { this.warn('undefined progressbar'); return false;}
  
    let bar = this.progressbar.querySelector('.bar');

    bar.style.width=percent+'%';
  
    if(percent != 0 ){
      progressInterval = setInterval(()=>updateProgress(bar),300);
    }else{
      clearInterval(progressInterval);
    } 
  }
  

  _fetchConsume(reader) {
      var total = 0
      return new Promise((resolve, reject) => {
        function pump() {
          reader.read().then(({done, value}) => {
            if (done) {
              resolve()
              return
            }
            total += value.byteLength
            log(`received ${value.byteLength} bytes (${total} bytes in total)`)
            pump()
          }).catch(reject)
        }
        pump()
      })
    }

    initForms(wrapper){
      this.initFormsGet(wrapper);
      this.initFormsPost(wrapper);
    }
    initFormsGet(wrapper){

      if(typeof wrapper == 'undefined')
        wrapper=document

        wrapper.querySelectorAll("form[method='GET']","form[method='get'],form:not([method])").forEach((f)=>{
          f.addEventListener('submit',(event)=>{
            event.preventDefault();
            // e.stopImmediatePropagation();
            event.stopPropagation();
            let form = event.target;
            this.log('submit get form',2);
            //let payload = new FormData(this);
            let uri = $(form).serialize();
            let action = form.action;
            this.fetchUrl(action+'?'+uri);
          })
        });

    }

  initFormsPost(wrapper) {
    if (typeof wrapper == 'undefined')
      wrapper = document


    wrapper.querySelectorAll("form[method='POST']", "form[method='post']").forEach((f) => {

      if (f.hasAttribute('data-ajax') && f.getAttribute('data-ajax') == 'false') return;

      f.addEventListener('submit',  (event)=> {
        event.preventDefault();
        event.stopPropagation();

        let form = event.target;
        let action = form.action;
        let payload = new FormData(form);
        //CHrome bug add button type submit 
        form.querySelectorAll('button').forEach(function (b) {
          payload.append(b.getAttribute('name'), b.value);
        });

        this.progress(5);

        let glue = '?'; if (action.indexOf('?') >= 0) glue = '&';
        //url ajax required for identity when post return errors
        //but auth not working 
        this.log('submit post: ' + action + glue + this.urlAjax,4);

        fetch(action + glue + this.urlAjax, {
          redirect: 'follow',
          method: 'POST',
          cache: 'no-cache',
          mode: 'no-cors',
          body: payload
        }).then(async response => {
          if (response.redirected) {
            this.log('redirected from post ' + response.url,2);

            glue = '?'; if (response.url.indexOf('?') >= 0) glue = '&';
            this.fetchUrl(response.url, { history: true }); //redirected page could be not json so again ()
            // console.log(ret); //IMPORTANT: allow to get redirected ajax page

          } else if (response.ok) {

            let ret = await response.clone().json().catch(() => response.text());
            if (typeof ret.page == 'undefined') {
              this.log('ok from post ',4);
              //if action is same as current url fetch 
              this.fetchUrl(action, { history: true });
            } else {
              this.log('load page from post ',4);
              this.loadPage(ret);
              this.pushHistory(action);
            }
          } else {
            this.error(response);
            throw 'some error';
          }
          this.progress(0);
          
        }).catch(e => {
          this.error(e);
        })
      });

    });
  }
    _popstatefunc(event){
          this.log('popstate',6);
          window.stop();
          event.preventDefault();
          event.stopPropagation();
          event.returnValue='';
      
        if(document.location.href.indexOf('#')<0){
          this.fetchUrl(document.location.href);
        }
        return '';

     }

    initPopstate(){

     this.log('init popstate',3);
    //  window.addEventListener('popstate', function(){ th});
     window.addEventListener('popstate',(e)=>{ return this._popstatefunc(e)});
    //  window.addEventListener('popstate',(e)=>{ return this._popstatefunc(e)});
  }

  _url_domain(data) {
    var    a      = document.createElement('a');
         a.href = data;
    return a.hostname;
  }
   pushHistory(href,title=null){
    let hrefClean = href.replace(['&action=page&HOOK_LEFT_COLUMN','?ajax=true','&ajax=true'],'');
  
    if(this._url_domain(hrefClean)!=document.location.hostname){
      this.error("Cannot push state different domain detected");
      return;
    }
    
    this.log('history : '+hrefClean,3);
    if(!title || !title.length) title = document.head.title;
  
    window.history.pushState(hrefClean,title,hrefClean); //couse problem when  before loadPage
  }
  
  initAsync(wrapper){
    this.initLinks(wrapper);
    this.initForms(wrapper);
    this.initPopstate();

    this.log('set up checkout');
    this.initCheckout();

  
    $('.ps-shown-by-js').show();
    $('.ps-hidden-by-js').hide();

  }

  openReadmore(ev){
      let  p = ev.target.parentNode;
      p.querySelector('span').remove();
      p.querySelector('a').remove();

      while(p.nextElementSibling){
        p=p.nextElementSibling;
        p.style.display='block';
      }
  }

  initReadmore(){
    return false;
    let target = document.querySelector('#custom-text');
    if(!target ) return false;
    if(window.innerWidth <  700) return false;

    target.querySelectorAll('p').forEach((p,i)=>{
      if(i==0){
          let a = document.createElement('a');
          a.href='javascript: superlayout.openReadmore(event2);';
          a.setAttribute('onclick','event2=event');
          a.innerHTML='read more';
          p.innerHTML+='<span>..</span>&nbsp;';
          p.appendChild(a);
          // p.innerHTML+='<span>..</span>&nbsp;<a href="#">read more</a>';
          return;
      } 
      p.style.display='none';
    })

    return true;
  }
  

   initMenuHover(){
    document.querySelectorAll('ul.top-menu > li').forEach(function(li){
      let timeout=null;

      if(false) li.addEventListener('mouseenter',()=>{
        li.classList.add('wait');
        timeout=setTimeout(function(){
          li.classList.remove('wait');
        },300)
      })
    })
  }


   initCheckout(){
    //find textarea and add buttons
    console.log("SL: init checkout")
    const div = document.querySelector('#delivery');
    if(!div ) return;


    const label=div.querySelector('label');
    const area = div.querySelector('textarea');

    console.log("LABEL" + label.innerHTML);
    const a = document.createElement('a');
    a.href='#';
    area.style.display='none';
    a.style.textDecoration='underline';
    a.addEventListener('click',function(ev){
      ev.preventDefault();
      // alert('click');
      area.style.display='block';
      return true;
    });
    a.innerHTML=label.innerHTML;
    label.innerHTML='';
    label.appendChild(a);

    console.log(label.innerHTML);
  }


}




