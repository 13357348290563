export default class PageCache { 
    iso_code='en';
    cache={'cookie':{'logged':false,'token':'token'}};
    constructor(){
        // if(window.superlayout.cache){ 
        // window.onload=()=>this.init;
        //}
        console.log("SL PC: boot");
        try{
            // this.cache.cookie=JSON.parse(window.localStorage.getItem('cookie'));
        }catch(e){ console.log(e);};
        
        if(typeof prestashop.language!=undefined)
            this.iso_code= prestashop.language.iso_code;
    }

    getCookieCurrencyOld(){ //deperecated
        let sign =document.cookie.match(new RegExp('(^| )currency=([^;]+)')); 
        if(!sign) return false;
        return sign[2];
    }

    getCurrencyId(){ 
        return (this.cache.cookie.id_currency);
    }
    
    html_loggedin(logged){
        if(logged==true){
            console.log('SL PC: customer logged');
            document.querySelectorAll('.user-info .logged-in').forEach(a=>a.style.display="inline");
            document.querySelectorAll('.user-info .logged-out').forEach(a=>a.style.display="none");
        }else console.log('SL PC: guest user');
    }

    async get_cookie(){
        console.log("SL PC: get cookie ");
        return await fetch('/module/superlayout?action=cookie').then(response=>response.json().then(json=>{
            this.html_loggedin(json.logged);
            this.cache.cookie=json;
            if ('localStorage' in window && window.localStorage !== null) {
                console.log("local storate supported: "+JSON.stringify(json));
                // window.localStorage.setItem('cookie',JSON.stringify(json));
            }
        }));
    }
    
    async init(){
        if(this.cache.cookie && this.cache.cookie.logged){
            this.html_loggedin(this.cache.cookie.logged)
        }
        await this.get_cookie();
        this.display_cart(); 
        let id=this.getCurrencyId();
        // if(!sign){ sign='GBP'; document.cookie='currency=GBP;path=/'; console.log('setting default cookie');}
        console.log("SL PC: page cache init currency id: " + id+' ...'); 
        if(id!=3 && id) this.change_currency(id,false);
    
          //set current iso 
          document.querySelectorAll('.currency-selector2.js-dropdown li a').forEach((a)=>{ a.addEventListener('click',(e)=>{
            // e.preventDefault();
            let iso = a.getAttribute('data-iso');
            // alert('changing from ' + sign + ' to currency to '+iso);
            // this.change_currency(iso);
            // return true;
          })});

          document.querySelectorAll('.user-info a.logged-in[href*="mylogout="]').forEach((a)=>{
            console.log('SL PC: registered logout a:' + a.classList);
            a.addEventListener('click',()=>{
                // event.preventDefault();
                this.cache.cookie.logged=false;
                // window.localStorage.setItem('cookie',JSON.stringify(this.cache.cookie));
                return true;
            });
          })

          console.log("SL PC: currency selector dynamic");
        // select_currency();
    }
    
    
    display_cart() {
        //refresh url 

        console.log('SL PC: display cart');
        let current = this.getCurrency();

        let blockcart  = document.querySelector('.blockcart');
        if(!blockcart) return;
        var refreshURL = blockcart.getAttribute('data-refresh-url');
        refreshURL+='?bypass';
        if(current)
            refreshURL+='&SubmitCurrency=1&id_currency='+current.id_currency;
        fetch(refreshURL).then(response => response.json()).then(json => {
            //console.log(json.preview);
            let preview = json.preview;

            var div = document.createElement('div');
            div.innerHTML = preview.trim();
            var cart = div.querySelector('.blockcart');
            // console.log(cart);

            document.querySelector('.blockcart').innerHTML = cart.innerHTML;
        });

   
        document.querySelectorAll('body[cache=fullpagecache] input[name=token]').forEach((i)=>{
            i.value=this.cache.cookie.token;
            console.log('SL PC: set token from cookie');
        });
    }
    getCurrency(){
        return this.getCurrencyById(this.getCurrencyId());
    }
    getCurrencyMain(){
        return Object.values(window.superlayout.config.currencies).find(o=>o.conversion_rate==1.0);
    }

    getCurrencyBySign(sign){
        return  window.superlayout.config.currencies[sign];

    }
    getCurrencyById(id){
        return Object.values(window.superlayout.config.currencies).find(o=>o.id_currency==id);
    }

    getCurrencyFromSign(dest){ //old, deprecated ,sagfe
        let current =null;
        if(typeof window.superlayout.config.currencies =='undefined') return null;
        if(parseFloat(dest)){
            current=   Object.values(window.superlayout.config.currencies).find(o=>o.id_currency==dest);
        }else
            current = window.superlayout.config.currencies[dest];
        return current; 
    }

    change_currency(id_currency,withCart=true) {
        if(!id_currency) return;
        // document.cookie='currency='+dest+';path=/';
        // console.log(document.cookie);
        let currency_main = this.getCurrencyMain();
        let sel = document.querySelector('.currency-selector.js-dropdown');
        let current = this.getCurrencyById(id_currency);
        if(!current){
            console.error('Cannot change currency. ');
            return;
        }
        console.log('SL PC: changing currency to ' + current.iso_code +  ' (id: ' +id_currency+') from '+ currency_main.iso_code);
        // console.log(document.cookie);
        if(withCart) this.display_cart();

        if (sel) {
            console.log('SL PC: setting current iso in selector iso: '+current.iso_code);
            let e = sel.querySelector('button span')
            if (e)e.innerHTML = current.iso_code;
            e.style.visibility='visible';

        }
        var getClosest = function (elem, func) {
            for (; elem && elem !== document; elem = elem.parentNode) {

                if (func(elem)) return elem;
            }
        };

        //remember about precision=0 in math_round 
        var priceConversion=(price,mode=null)=>{
            price *=current.conversion_rate;
            if(isNaN(price)) return;
             if(!mode) mode= parseInt(window.superlayout.config.round_mode);

            if(mode==2){
                if((Math.ceil(price)-price)>=0.5){

                }
                price=price.toFixed();

            }else 
                price=price.toFixed(mode);

            return price;

        }
        
        var convertPrice= (price)=>{
            price=priceConversion(price);
            let d = [price,current.symbol];
        
            if(['en','ja','nl','zh','ar','tr','pt'].includes(this.iso_code))
                d.reverse();

            try{
                return new Intl.NumberFormat(this.iso_code, { style: "currency", currency: current.iso_code }).format(
                    price,
                  );
                }catch(e){

             }
            return  d.join(' ');
        }
        console.log('SL PC: converting prices with mode ' + window.superlayout.config.round_mode);
        document.querySelectorAll('.product-flag.discount:not(.percentage)').forEach(function (e) {
            // debugger;
            var par = getClosest(e, function (p) {
                if (p.getAttribute('data-id-product')) { return true; }
                if (p.classList.contains('js-product-container')) { return true; }
            });

            if (!par) return;
            // let id_product = par.getAttribute('data-id-product');;
            // let id_attribute = par.getAttribute('data-id-product-attribute');
            e.style.visibility='visible';
            let r= par.querySelector("[name=price_old][data-type=amount]");
            if(!r) return;
            //find hidden element and apply 
            let price = parseFloat(r.value);
     
            e.innerHTML = '- '+convertPrice(price);
            // alert(e.innerHTML);
            // console.log('SL PC: changing discount flags '+id_product);
        })

        document.querySelectorAll('.js-price-save').forEach(function (e) {
            var par = getClosest(e, function (p) {
                if (p.getAttribute('data-id-product')) { return true; }
                if (p.classList.contains('js-product-container')) { return true; }
            });
            if (!par) return;
            e.style.visibility='visible';
            let r= par.querySelector("[name=price_old]");
            if(!r || !r.value) { console.log('price cold not found'); return;}
            let price = parseFloat(r.value);
     
            e.innerHTML = convertPrice(price);
        });


        document.querySelectorAll('.js-price').forEach((e) =>{

            let prev=e.previousElementSibling;
            if(prev.name=='price_gbp'){
                let price = prev.value;
                let price_old=false;
                let discount=0;
                let info='';
              
                if(prev.hasAttribute('data-price-old') && prev.hasAttribute('data-discount')){
                    price_old=prev.getAttribute('data-price-old');
                    discount=prev.getAttribute('data-discount');
                }
                
                let html_price=convertPrice(price);
                if(price_old && discount){ //not used for now
                    // info='product with discount calculation';
                    // html_price=priceConversion(price_old,10) - priceConversion(discount,10);
                }
                e.innerHTML=  html_price; 

                e.style.visibility='visible';

                console.log('SL PC: changing product js-price '+current.iso_code+' conversion: ' +current.conversion_rate + ' '+info);
            }
        });
    }
    
  initCurrency(){//body:not([cache]) 
    console.log("SL PC: init ajax currency changing");
    document.querySelectorAll('.currency-selector a[data-iso]').forEach((a)=>{
      // console.log(a.getAttribute('data-id'));
      a.addEventListener('click',(e)=>{
        // console.log("SL PC: change currency")
        e.preventDefault();
        this.setCurrency(a.getAttribute('data-id'));
        return true;
      })
    });

    // document.querySelector(".currency-selector select").addEventListener("change",(t)=>{
    //     let value=t.value;

    //     console.log(t);
    // });

  }
  setCurrency(id_currency){
      const urlObj = new URL(window.location.href);
      urlObj.search = '';
      urlObj.hash = '';
      let url =urlObj.toString()+'?SubmitCurrency=1&ajax=1&id_currency='+id_currency;
      console.log('SL PC: changing currency '+id_currency+' url: '+url); 

      // if(document.querySelector('body:not([cache])'))
    //   this.change_currency(id_currency,false);
    //   this.cache.cookie.id_currency=id_currency;

      fetch(url)
      .then((response)=>{ 
            let ok = response.status==200;
            response.text().then(text=>{
            // alert(text);
             //if page cacheed 
            // this.change_currency(id_currency,false);
            //  this.get_cookie();
              window.location.reload();
        })
      });
  }

}

